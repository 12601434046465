import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import voicesReducer from "../modules/workSpace/redux/voicesSlice";
import voicesListReducer from "../modules/workSpace/redux/voicesListSlice";
import videoReducer from "../modules/workSpace/redux/videoSlice";
import projectMetaReducer from "../modules/workSpace/redux/projectMetaSlice";
import authStateReducer from "../modules/auth/redux/authSlice";
import MGSliceReducer from "../modules/workSpace/redux/MGSlice";
import dictionarySliceReducer from "../modules/workSpace/redux/dictionarySlice";
import AIMusicSliceReducer from "../modules/workSpace/redux/AIMusicSlice";
import AIMusicbrandConfigsReducer from "../modules/workSpace/redux/AIMusicbrandConfigsSlice";
import notificationReducer from "../common/redux/notificationSlice";
import notificationTopBarReducer from "../common/redux/notificationTopBarSlice";
import commonReducer from "../common/redux/commonSlice";
import loaderReducer from "../common/redux/loaderSlice";
import tableReducer from "../modules/workSpace/redux/tableSlice";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "voicesList",
    "notification",
    "loader",
    "dictionary",
    "brandConfigs",
    "table",
  ],
};

const rootReducer = combineReducers({
  voices: voicesReducer,
  video: videoReducer,
  projectMeta: projectMetaReducer,
  voicesList: voicesListReducer,
  auth: authStateReducer,
  MGSlice: MGSliceReducer,
  AIMusic: AIMusicSliceReducer,
  notification: notificationReducer,
  notificationTopBar: notificationTopBarReducer,
  commonReducer,
  dictionary: dictionarySliceReducer,
  loader: loaderReducer,
  brandConfigs: AIMusicbrandConfigsReducer,
  table: tableReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  // devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
});

export const persistor = persistStore(store);

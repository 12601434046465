import React, { Component } from "react";
import { IntlProvider } from "react-intl";
import FallBackPage from "../../common/components/FallBackPage/FallBackPage";
import { flattenMessages } from "../../utils/messagesUtils";
import BrandingService from "../BrandingService";
import { BrandingContext } from "./BrandingContext";
import StylingProvider from "./StylingProvider";
import getConfigJSON from "../../common/helperFunctions/getConfigJSON";

class BrandingProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: { isDefault: true },
      messages: { isDefault: true },
      isLoading: true,
    };
  }

  componentDidMount() {
    BrandingService.get().then(async (result) => {
      const jsonConfig = await getConfigJSON();
      this.setState(
        {
          config: result[0],
          jsonConfig,
          messages: result[1],
          isLoading: false,
        },
        () => {
          this.setBodyFonts();
        }
      );
    });
  }

  setBodyFonts() {
    var fonts = document.createElement("style");
    fonts.appendChild(document.createTextNode(this.state.config.fonts));
    document.head.appendChild(fonts);
  }

  renderLoading() {
    return <FallBackPage />;
  }

  render() {
    const { children } = this.props;
    const { isLoading, config, messages } = this.state;
    if (isLoading) {
      return this.renderLoading();
    }
    return (
      <BrandingContext.Provider value={this.state}>
        <IntlProvider locale="en" messages={flattenMessages(messages)}>
          <StylingProvider stylingVariables={config.theme}>
            {children}
          </StylingProvider>
        </IntlProvider>
      </BrandingContext.Provider>
    );
  }
}

export default BrandingProvider;
